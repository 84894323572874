import React from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from "react-pro-sidebar";
import { SessionContextProvider } from './contexts/SessionContext';
import { AppContextProvider } from './contexts/AppContext';
import { DataContextProvider } from './contexts/DataContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ProSidebarProvider>
      <SessionContextProvider>
        <AppContextProvider>
          <DataContextProvider>
            <App />
          </DataContextProvider>
        </AppContextProvider>
      </SessionContextProvider>
    </ProSidebarProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
