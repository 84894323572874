import React from "react";
import {
  FormControl,
  FormHelperText,
  Input,
  FormLabel,
  Box,
  Container,
  Typography,
  Stack,
  InputLabel,
  FormGroup,
  TextField,
  Grid,
  Card,
  CardContent,
  Button,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "axios";

import { SessionContext } from "../../../contexts/SessionContext";

export default function Invite() {
  const { userSession } = React.useContext(SessionContext);

  const [formData, setFormData] = React.useState({ 'new_user_email': "", 'brand_integrations': [] });
  const [status, setStatus] = React.useState("form");

  const handleChangeEmail = (e) => {
    setFormData({ ...formData, 'user_email': e.target.value });
  };

  const handleBrandAdd = (e) => {
    setFormData({
      ...formData,
      'brand_integrations': [
        ...formData.brand_integrations,
        { 'platform': "", 'shop_pk': "", 'brand_name': "" },
      ],
    });
  };

  const handleBrandDelete = (i) => {
    setFormData({
      ...formData,
      brand_integrations: [...formData.brand_integrations.slice(0, i), ...formData.brand_integrations.slice(i + 1)],
    });
  };

  const handlePlatformChange = (i, platform) => {
    setFormData({
      ...formData,
      brand_integrations: [
        ...formData.brand_integrations.slice(0, i),
        { ...formData.brand_integrations[i], 'platform': platform },
        ...formData.brand_integrations.slice(i + 1),
      ],
    });
  };

  const handleSellerIdChange = (i, seller_id) => {
    setFormData({
      ...formData,
      brand_integrations: [
        ...formData.brand_integrations.slice(0, i),
        { ...formData.brand_integrations[i], 'shop_pk': seller_id },
        ...formData.brand_integrations.slice(i + 1),
      ],
    });
  };

  const handleBrandNameChange = (i, brand_name) => {
    setFormData({
      ...formData,
      brand_integrations: [
        ...formData.brand_integrations.slice(0, i),
        { ...formData.brand_integrations[i], 'brand_name': brand_name },
        ...formData.brand_integrations.slice(i + 1),
      ],
    });
  };

  const handleRegionChange = (i, region) => {
    setFormData({
      ...formData,
      brand_integrations: [
        ...formData.brand_integrations.slice(0, i),
        { ...formData.brand_integrations[i], 'region': region },
        ...formData.brand_integrations.slice(i + 1),
      ],
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // const user_email = userSession.idToken.payload.email
    const uri = `${process.env.REACT_APP_PROD_BACKEND}/user/invite`;
    const config = {
      headers: {
        "Authorization": userSession.idToken.jwtToken
      },
    };

    axios
      .post(uri, formData, config)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Post successful!", res);
          setStatus("successful");
        } else {
          console.log("Post failed!", res);
          setStatus("failed");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setStatus("failed");
      });
  };

  return (
    <div>
      <div className="font-bold text-xl text-center ">
        Invite a New User
      </div>

      <div className="flex justify-center items-center mt-8">
        <div className="flex max-w-md mx-auto  bg-white rounded-lg border-solid border border-borderColor flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            {status == "form" && (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div container>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Enter user email
                  </label>
                  <div item className="mt-2">
                    <input
                      type="email"
                      placeholder="User email "
                      label="Seller Email"
                      variant="outlined"
                      value={formData.email}
                      onChange={handleChangeEmail}
                      fullWidth
                      required
                      className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div item className="mt-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Brands to Integrate:
                    </label>
                  </div>

                  {formData.brand_integrations.map((brand, i) => (
                    <>
                      <div item>
                        <label
                          htmlFor="email"
                          className="block mt-2 text-sm font-medium leading-6 text-gray-900"
                        >
                          Platform
                        </label>
                        <div item className="mt-1">
                          <select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={brand["platform"]}
                            onChange={(e) => {
                              handlePlatformChange(i, e.target.value);
                            }}
                            fullWidth
                            required
                            className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                          >
                            <option value="">
                              <em>None</em>
                            </option>
                            <option value="Amazon">Amazon</option>
                            {/* <option value="Shopify">Shopify</option>
                            <option value="Walmart">Walmart</option> */}
                          </select>
                        </div>
                      </div>

                      <div item>
                        <label
                          htmlFor="email"
                          className="block mt-4 text-sm font-medium leading-6 text-gray-900"
                        >
                          Enter seller ID
                        </label>
                        <div item className="mt-1">
                        <input
                          placeholder="Seller ID"
                          label="Seller ID"
                          variant="outlined"
                          onChange={(e) => {
                            handleSellerIdChange(i, e.target.value);
                          }}
                          value={brand["seller_id"]}
                          fullWidth
                          required
                          className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                        />
                      </div>   </div>

                      <div item>
                      <label
                          htmlFor="email"
                          className="block mt-4 text-sm font-medium leading-6 text-gray-900"
                        >
                       Enter brand name
                        </label>
                        <div item className="mt-1">
                        <input
                          placeholder="Brand name"
                          label="Brand Name"
                          variant="outlined"
                          onChange={(e) => {
                            handleBrandNameChange(i, e.target.value);
                          }}
                          value={brand["brand_name"]}
                          fullWidth
                          required
                          className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                        />
                      </div>
                      </div>

                      <div item className="mt-2">
                          <label
                              htmlFor="email"
                              className="block mt-4 text-sm font-medium leading-6 text-gray-900"
                            >
                          Enter region
                            </label>
                          <select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={brand["region"]}
                            onChange={(e) => {
                              handleRegionChange(i, e.target.value);
                            }}
                            fullWidth
                            required
                            className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                          >
                            <option value="">
                              <em>None</em>
                            </option>
                            <option value="EU">Europe</option>
                            <option value="US">North America</option>
                          </select>
                          </div>



                      <div item  className="mt-2 text-reject">
                        <button
                          onClick={(e) => {
                            handleBrandDelete(i);
                          }}
                          color="error"
                        >
                          <DeleteOutlineOutlinedIcon />
                        </button>
                      </div>
                    </>
                  ))}

                  <div item className="mt-2 text-indigo-100">
                    <button onClick={handleBrandAdd}>
                      <AddIcon />
                    </button>
                  </div>

                  <div item >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="px-5 py-3 grow uppercase min-w-[120px]  bg-indigo-100 text-white  rounded-lg"
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </form>
            )}
            {status == "successful" && (
              <Typography variant="h2" color="successful">
                {" "}
                Invite sent Successfully!
              </Typography>
            )}
            {status == "failed" && (
              <Typography variant="h2" color="error">
                {" "}
                Invitation Failed{" "}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
