import React from "react";
import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";
import { ShimmerTitle } from "react-shimmer-effects";
import { ShimmerButton } from "react-shimmer-effects";
import Search from "./Search";
import Details from "./Details";

import { SessionContext } from "../../../contexts/SessionContext";
import { DataContext } from "../../../contexts/DataContext";

export default function Home() {
  const { userSession } = React.useContext(SessionContext);
  const { brandsData, setBrandsData } = React.useContext(DataContext);
  const [loading, setLoading] = React.useState(true);

  const [selectedBrand, setSelectedBrand] = React.useState(null);
  const [filters, setFilters] = React.useState({"platform": "all_platforms", "status": "all_statuses"});
  const [brandsMap, setBrandsMap] = React.useState({});
  const [filteredBrands, setFilteredBrands] = React.useState([]);

  const [query, setQuery] = React.useState("");

  React.useEffect(() => {
    (async () => {
      const uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/get_data`;
      const config = {
        headers: {
          Authorization: userSession.idToken.jwtToken
        },
      };
      const payload = {
        type: 'all'
      }
      try{
        let res = await axios.post(uri, payload, config)
        setBrandsData(res.data.data);
        setLoading(false);
      } catch (err) {
        console.log("data api call failed: ", err);
      }
    })()
  }, []);

  React.useEffect(() => {
    if (brandsData === null) return;

    let b_map = {};

    brandsData.forEach((item) => {
      if (item["brand_name"] in b_map) b_map[item["brand_name"]].push(item);
      else b_map[item["brand_name"]] = [item];

      if ("all_brands" in b_map) b_map["all_brands"].push(item);
      else b_map["all_brands"] = [item];
    });

    setBrandsMap(b_map);

    const brandNames = Object.keys(b_map);
    const t = brandNames.filter((b_name) =>
      b_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBrands(t);
    setSelectedBrand("all_brands");
  }, [brandsData, query]);

  return (
    <>
      <div className="font-bold text-base">All Integrations</div>

      <div className="mt-5">
        {loading ? (
          <div className="w-full mt-5">
            <div className="flex gap-10">
              <ShimmerButton size="md" />
              <ShimmerButton size="md" />
              <ShimmerButton size="md" />
              <ShimmerButton size="md" />
            </div>
            <ShimmerTitle line={1} variant="primary" />
            <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={20} />
          </div>
        ) : (
          <Search
            selectedBrand={selectedBrand}
            filters={filters}
            setFilters={setFilters}
            setQuery={setQuery}
          />
        )}
        <Details
          className="mt-6"
          brandDetails={
            (selectedBrand != null) & brandsMap.hasOwnProperty(selectedBrand)
              ? brandsMap[selectedBrand]
              : []
          }
          filters={filters}
          b_name={selectedBrand ? selectedBrand : "Not selected"}
          filteredBrands={filteredBrands}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      </div>
    </>
  );
}
