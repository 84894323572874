import React from 'react';

import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MailIcon from '@mui/icons-material/Mail';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


import Home from "./Home"
import Integrate from "./Integrate"
import Assign from "./Assign";
import Invite from "./Invite";
import Support from "./Support";
import Reports from "./Reports";

import { SessionContext } from '../../contexts/SessionContext';
import { AppContext } from '../../contexts/AppContext';

import UserPool from '../../UserPool';


export default function Dashboard(){

    const {userSession} = React.useContext(SessionContext)
    const {currentTab, setCurrentTab} = React.useContext(AppContext)

    const userRole = userSession.idToken.payload['cognito:groups'][0]

    React.useEffect(() => {
        setTimeout(() => {
            logout()
        }, 60*60*1000);
    }, [])    

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
  
    const toggle = () => {
      toggleSidebar();
      collapseSidebar();
    };

    const logout = () => {
        const currentUser = UserPool.getCurrentUser()

        currentUser.signOut(() => {
            console.log("Signed out");
            window.location.reload();
        })
    }

    return (
        <div id="app" className=' flex  h-screen '>
            <Sidebar id='sidebar' className='z-20 hidden w-64 overflow-y-auto bg-sideBar md:block flex-shrink-0'
           
            >
                    <div className='py-4 text-gray-500 dark:text-gray-400 bg-sideBar'> 
                    <Menu>
                        <MenuItem icon={<MenuOutlinedIcon />} onClick={() => {toggle();}} className='relative px-6 py-3'><h2>{userRole=='admin'?"Admin":"User"}</h2> </MenuItem>
                        <MenuItem icon={<HomeOutlinedIcon />} onClick={() => {setCurrentTab('Home')}} className='mt-2 relative px-6 py-3'>Home</MenuItem>
                        <MenuItem icon={<CallMergeIcon />} onClick={() => { setCurrentTab('Integrate')}} className='relative px-6 py-3'> Intergate a Brand</MenuItem>
                        {
                            userRole == 'admin' && <>
                                <MenuItem icon={<AttachFileIcon/>} onClick={() => {setCurrentTab('Assign')}} className='relative px-6 py-3'> Assign Integratation</MenuItem>
                                <MenuItem icon={<MailIcon/>} onClick={() => {setCurrentTab('Invite')}} className='relative px-6 py-3'> Invite User</MenuItem>
                                <MenuItem icon={<SupportAgentIcon/>} onClick={() => {setCurrentTab('Support')}} className='relative px-6 py-3'>Support</MenuItem>
                                
                            </>
                        }
                        <MenuItem icon={<ExitToAppIcon />} onClick={logout} className='relative px-6 py-3'>Logout</MenuItem>
                    </Menu>
                    </div>
            </Sidebar>

            <main className='flex h-screen grow'>
            <div className="py-9 px-9 overflow-y-auto grow">
                    {currentTab === "Home" && <Home/>}
                    {currentTab === "Integrate" && <Integrate />}
                    {currentTab === "Reports" && <Reports />}
                    {
                        userRole=='admin' && <>
                            {currentTab === "Assign" && <Assign/>}
                            {currentTab === "Invite" && <Invite/>}
                            {currentTab === "Support" && <Support />}
                        </>
                    }
                </div>
            </main>
          
        </div>
        
    );
}