import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'

import UserPool from './UserPool';
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import IntegrateRedirect from "./components/IntegrateRedirect";
import IntegrateRedirectAds from "./components/IntegrateRedirect/IntegrateRedirectAds";
import { SessionContext } from "./contexts/SessionContext";
import { AppContext } from "./contexts/AppContext";



export default function App() {

  const currentUser = UserPool.getCurrentUser()
  const {setUserSession} = React.useContext(SessionContext)
  const {setLoginStatus} = React.useContext(AppContext)

  
  React.useEffect(() => {
    if(currentUser){
      currentUser.getSession((err, session) => {
        if(err){
          console.log(err);
          setLoginStatus('none')
        }else{
          // console.log("Groups", session.idToken.payload['cognito:groups']);
          setUserSession(session)
          setLoginStatus('valid')
        }
      })
    }else{
      setLoginStatus('none')
    }

  }, [])


  return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/login' element={<LoginWrapper/>} /> 
          <Route exact path='/' element={<DashboardWrapper />}/>
          <Route exact path='/integrate-redirect' element={<IntegrateRedirectWrapper platform='AMAZON'/>}/>
          <Route exact path='/integrate-redirect/ads' element={<IntegrateRedirectWrapper platform='AMAZON_ADS'/>}/>
        </Routes>
      </BrowserRouter>
  )
}

const LoginWrapper = () => {
  const {loginStatus} = React.useContext(AppContext)
  
  if(loginStatus === 'none'){
    return <Login/>
  }else{
   return <Navigate to='/' />
  }
}

const DashboardWrapper = () => {
  const {loginStatus} = React.useContext(AppContext)

  if(loginStatus === 'none'){
    return <Navigate to='/login' />
  }else if(loginStatus === 'loading'){
    return <div>Loading ...</div>
  }else{
    return <Dashboard/>
  }
}

const IntegrateRedirectWrapper = ({platform}) => {
  const {loginStatus} = React.useContext(AppContext)

  if (loginStatus === 'none'){
    return <Navigate to='/login' />
  }else if (loginStatus === 'loading'){
    return <div>Loading ...</div>
  }else if (platform === 'AMAZON'){
    return <IntegrateRedirect/>
  }else if (platform === 'AMAZON_ADS'){
    return <IntegrateRedirectAds/>
  }
}

