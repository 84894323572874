import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";

import IntegrationSteps from "./IntegrationSteps";
import { SessionContext } from "../../../contexts/SessionContext";
import { default_intg_form_data, DataContext } from "../../../contexts/DataContext";

export default function Integrate() {

  const {intgFormData, setIntgFormData, intgData, setIntgData } = React.useContext(DataContext)
  const { userSession } = React.useContext(SessionContext);

  const userRole = userSession.idToken.payload['cognito:groups'][0]

  React.useEffect(() => {
    if (intgFormData.submit){
      // Automatically calling submit functionality
      console.log("Auto Submit");
      handleSubmit()
      setIntgFormData({...intgFormData, submit: false})
    }
  }, [])

  const handleFormDataChange = (e) => {
    if (userRole === "admin"){
      const form_data = {...intgFormData}
      form_data[e.target.name] = e.target.value
      setIntgFormData(form_data)
    }
  }

  const handleClear = (e) => {
    setIntgFormData(default_intg_form_data)
  }

  const handleSubmit = (e) => {
    try{
      e.preventDefault();
    }
    catch{
      console.log("Auto submit");
    }
    finally{
      setIntgData({ api_status: "loading" });

      // const key = `${intgFormData["platform"]}_${intgFormData["seller_id"]}_${intgFormData["region"]}_${intgFormData["brand_name"]}`

      const uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/get_data`;
      const config = {
        headers: {
          Authorization: userSession.idToken.jwtToken
        },
      };
      const payload = {
        type: 'single',
        platform: intgFormData["platform"],
        seller_id: intgFormData["seller_id"],
        region: intgFormData["region"],
        brand_name: intgFormData["brand_name"]
      }

      axios
        .post(uri, payload, config)
        .then((res) => {
          if (res.status == 200) {
            const data = res.data.data;
            if (data) setIntgData({ api_status: "success", data: data });
            else setIntgData({ api_status: "no_data" });
          }
        })
        .catch((err) => {
          console.log(err);
          setIntgData({ api_status: "failed" });
        });
    }
  };

  return (
    <div>
      <div className="font-bold text-xl text-center ">Integrate a Brand</div>

      <div className="flex justify-center items-center mt-8">

        <div className="flex max-w-md mx-auto  bg-white rounded-lg border-solid border border-borderColor flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <form className="space-y-6" onSubmit={handleSubmit} id='intg-form'>
                <div container spacing={1}>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    * Enter details of an existing brand integration
                  </label>

                  <div item className="mt-8">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Platform
                    </label>
                    <div className="mt-2">
                      <select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="platform"
                        value={intgFormData.platform}
                        onChange={handleFormDataChange}
                        fullWidth
                        required
                        className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                      >
                        <option value="">
                          <em>None</em>
                        </option>
                        <option value="Amazon">Amazon</option>
                        <option value="Amazon_Ads">Amazon Ads</option>
                        {/* <option value="Shopify">Shopify</option> */}
                        {/* <option value="Walmart">Walmart</option> */}
                      </select>
                    </div>
                  </div>
                  <div item className="mt-8">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Seller ID
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="seller ID"
                        label="Seller ID"
                        variant="outlined"
                        name="seller_id"
                        value={intgFormData.seller_id}
                        onChange={handleFormDataChange}
                        className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                        fullWidth
                        required
                      />
                    </div>
                  </div>

                  <div className="mt-8" item>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Region
                    </label>
                    <div className="mt-2">
                      <select
                        labelId="demo-simple-select-helper-label "
                        id="demo-simple-select-helper"
                        name="region"
                        value={intgFormData.region}
                        onChange={handleFormDataChange}
                        fullWidth
                        required
                        className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                      >
                        <option value="">
                          <em>None</em>
                        </option>
                        <option value="EU">Europe</option>
                        <option value="US">North America</option>
                      </select>
                    </div>
                  </div>

                  <div item className="mt-8">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Brand Name
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="Brand Name"
                        label="Brand Name"
                        variant="outlined"
                        name="brand_name"
                        value={intgFormData.brand_name}
                        onChange={handleFormDataChange}
                        className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                        fullWidth
                        required
                      />
                    </div>
                  </div>

                  { userRole == "admin" && 
                    <div item className="flex gap-8 mt-6">
                      <button
                        type="submit"
                        variant="contained"
                        color="success"
                        fullWidth
                        className="px-4 grow uppercase min-w-[120px]  py-3 bg-indigo-100 text-white rounded-lg"
                        >
                        Integrate
                      </button>

                      <button
                        variant="contained"
                        color="error"
                        fullWidth
                        className="px-4 uppercase l  py-3 b text-reject border border-reject  min-w-[120px]  rounded-lg"
                        onClick={handleClear}
                        >
                        Clear
                      </button>
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="flex max-w-md mx-auto  bg-white rounded-lg border-solid border border-borderColor flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          {intgData["api_status"] === "none" && (
            <div>Please fill the details to fetch the data</div>
          )}
          {intgData["api_status"] === "loading" && (
            <div>
              <BeatLoader />
            </div>
          )}
          {intgData["api_status"] === "failed" && <div>API call failed</div>}
          {intgData["api_status"] === "no_data" && <div>Sorry no data</div>}
          {intgData["api_status"] === "success" && (
            <div>
              <IntegrationSteps/>
            </div>
          )}
        </div>

      </div>
    </div>
  );
}
