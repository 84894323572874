import React from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { Switch } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

import { SessionContext } from "../../../contexts/SessionContext";
import { DataContext } from "../../../contexts/DataContext";
import { AppContext } from "../../../contexts/AppContext";

export default function Reports() {
  const { userSession } = React.useContext(SessionContext);
  const { reportsData } = React.useContext(DataContext);
  const { setCurrentTab } = React.useContext(AppContext);

  const [state, setState] = React.useState({ api_status: "loading" });

  React.useEffect(() => {
    (async () => {
      const seller_key = reportsData["seller_key"];

      let uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/get_reports?seller_key=${seller_key}`;
      const config = {
        headers: {
          Authorization: userSession.idToken.jwtToken
        },
      };

      try {
        let res = await axios.get(uri, config);
        let data = res.data.data.map((x) => {
          return { ...x, toggle: x["is_active"] };
        });
        setState({ api_status: "success", data: data });
      } catch (err) {
        console.log("API call failed", err);
        setState({ ...state, api_status: "failed" });
      }
    })();
  }, [reportsData]);

  const handleToggle = (i, checked) => {
    let x = state["data"][i];
    x["toggle"] = checked;
    setState({
      ...state,
      data: [...state["data"].slice(0, i), x, ...state["data"].slice(i + 1)],
    });
  };

  const toggleAll = (e) => {
    let data = state["data"].map((x) => {
      return { ...x, toggle: e.target.checked };
    });
    setState({ ...state, data: data });
  };

  const handleSave = async (e) => {
    setState({ ...state, api_status: "loading" });

    let uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/update_reports_status`;
    let config = {
      headers: {
        Authorization: userSession.idToken.jwtToken
      },
    };

    let payload_data = state["data"]
      .filter((x) => x["is_active"] != x["toggle"])
      .map(({ seller_key, report_type, toggle }) => ({
        seller_key,
        report_type,
        is_active: toggle,
      }));
    let payload = {
      data: payload_data,
    };

    try {
      let res = await axios.post(uri, payload, config);
      let data = state["data"].map((x) => {
        return { ...x, is_active: x["toggle"] };
      });
      setState({ ...state, api_status: "success", data: data });
    } catch (err) {
      console.log("API call failed 1 ", err);
      // If updation failed, fetch the table data afresh and display
      const seller_key = reportsData["seller_key"];
      let uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/get_reports?seller_key=${seller_key}`;
      try {
        let res = await axios.get(uri, config);
        let data = res.data.data.map((x) => {
          return { ...x, toggle: x["is_active"] };
        });
        setState({ api_status: "success", data: data });
      } catch (err) {
        console.log("API call failed 2 ", err);
        setState({ ...state, api_status: "failed" });
      }
    }
  };

  return (
    <>
      <div className="flex justify-between w-full">
        <IconButton
          aria-label="go-back"
          size="large"
          onClick={() => {
            setCurrentTab("Home");
          }}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <div className="font-bold  text-xl text-center">Reports</div>
        <button
          variant="outlined"
          color="success"
          onClick={handleSave}
          className="px-4 uppercase py-2 bg-indigo-100 text-white rounded-lg"
        >
          Save
        </button>
      </div>

      <div className="overflow-x-auto table-fixed mt-6  mx-auto border border-borderColor rounded-lg">
        <table className="min-w-full">
          <thead>
            <tr className="bold border-b border-borderColor">
              <th className="px-6 py-5  text-left  font-bold text-s leading-4  text-gray-500 uppercase tracking-wider">
                Report name
              </th>
              <th className="px-6 py-5  text-left  bold text-s leading-4  text-gray-500 uppercase tracking-wider">
                Last updated date
              </th>
              <th className="px-6 py-5  text-left  bold text-s leading-4  text-gray-500 uppercase tracking-wider">
                Errors
              </th>
              <th className="px-6 py-5  text-left  bold text-s leading-4  text-gray-500 uppercase tracking-wider">
                <Switch onChange={toggleAll}/>
                <span className="text-xs" style={{fontSize: '13px'}}>(Status)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {state["api_status"] === "success" &&
              state["data"].map((item, i) => (
                <tr className="border-b border-borderColor">
                  <td className="px-6 py-4 whitespace-no-wrap  ">
                    {item["report_type"]}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap  ">
                    {item["last_report_update"]}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap  "> ... </td>
                  <td className="px-6 py-4 whitespace-no-wrap  ">
                    <Switch
                      checked={item["toggle"]}
                      onChange={(e) => {
                        handleToggle(i, e.target.checked);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {state["api_status"] === "loading" && (
          <div className="w-full text-center mt-10">
            <BeatLoader />
          </div>
        )}
        {state["api_status"] === "failed" && <div>API call failed.</div>}
      </div>
    </>
  );
}
