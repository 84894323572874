import React from "react";

export default function Support() {
  const [formData, setFormData] = React.useState({
    email: "",
    Name: "",
    msg: "",
  });

  const handleEmailChange = e => {
    setFormData({...formData, email: e.target.value})
}
const handleNameChange = e => {
    setFormData({...formData, Name: e.target.value})
}
const handleMsgChange = e => {
    setFormData({...formData, msg: e.target.value})
}

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="font-bold text-xl text-center ">Support</div>

      <div className="flex justify-center items-center mt-8">
        <div className="flex max-w-md mx-auto  bg-white rounded-lg border-solid border border-borderColor flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <div>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div container>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      placeholder="Enter Email "
                      label="User Email"
                      variant="outlined"
                      value={formData.email}
                      onChange={handleEmailChange}
                      fullWidth
                      required
                      className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div item className="mt-8">
                    

                    <div item className="mt-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          placeholder="Full Name"
                          label="Seller ID"
                          variant="outlined"
                          value={formData.Name}
                          onChange={handleNameChange}
                          className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                          fullWidth
                          required
                        />
                      </div>
                    </div>
                    <div item className="mt-8">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Message
                      </label>
                      <div className="mt-2">
                        <textarea
                          placeholder="Full Name"
                          label="Brand Name"
                          variant="outlined"
                          value={formData.msg}
                          rows={5}
                          onChange={handleMsgChange}
                          fullWidth
                          required
                          className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div item className="flex gap-8 mt-6">
                      <button
                        type="submit"
                        variant="contained"
                        color="success"
                        fullWidth
                        className="px-4 grow uppercase min-w-[120px]  py-3 bg-indigo-100 text-white rounded-lg"
                      >
                        Submit
                      </button>

                      <button
                        variant="contained"
                        color="error"
                        fullWidth
                        className="px-4 uppercase l  py-3 b text-reject border border-reject  min-w-[120px]  rounded-lg"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
