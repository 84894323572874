import React from "react";
import {
  Typography,
  Button,
} from "@mui/material";
import axios from 'axios'
import { SessionContext } from "../../../contexts/SessionContext";


export default function Assign() {

  const [formData, setFormData] = React.useState({email: '', platform: '', seller_id: '', brand_name: '', region: ''})
  const { userSession } = React.useContext(SessionContext);
  const [screen, setScreen] = React.useState('form')

  const handleChangeEmail = e => {
      setFormData({...formData, email: e.target.value})
  }

  const handlePlatformChange = e => {
    setFormData({...formData, platform: e.target.value})
  }

  const handleSellerIdChange = e => {
      setFormData({...formData, seller_id: e.target.value})
  }

  const handleBrandNameChange = e => {
      setFormData({...formData, brand_name: e.target.value})
  }

  const handleRegionChange = e => {
    setFormData({...formData, region: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()
    
    const uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/assign_user`;
    const payload = {
      'assignee_email': formData['email'],
      'assign_type': 'new-assign',
      'platform': formData['platform'],
      'shop_pk': formData['seller_id'],
      'region': formData['region'],
      'brand_name': formData['brand_name']
    }
    const config = {
      headers: {
        "Authorization": userSession.idToken.jwtToken
      },
    };

    axios
      .post(uri, payload, config)
      .then((res) => {
        if (res.status == 200) {
          console.log("Successfully assigned the user.");
          setScreen('success')
        }
      })
      .catch((err) => {
        console.log("User Assign Failed: ", err);
        setScreen('failed')
      });
  }

  if(screen === 'form')
  return (
        <div>
          <div className="font-bold text-xl text-center ">
                Assign a Brand Integration
          </div>
          <div className="flex justify-center items-center mt-8">
        <div className="flex max-w-md mx-auto  bg-white rounded-lg border-solid border border-borderColor flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <div>
              <div >
                <div>
                
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div container >
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Assignee Email (Existing user)
              </label>
                      <div item className="mt-2">
                        <input type="email" placeholder="Enter assignee email " label="Assignee Email" variant="outlined" value={formData.email} onChange={handleChangeEmail} fullWidth required   className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"/>
                      </div>

                      <div item className="mt-6">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Platform
              </label>
              <div item className="mt-2">
                          <select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={formData.platform}
                            onChange={handlePlatformChange}
                            fullWidth
                            required
                            className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                          >
                            <option value=''><em>None</em></option>
                            <option value='Amazon'>Amazon</option>
                            {/* <option value='Shopify'>Shopify</option>
                            <option value='Walmart'>Walmart</option> */}
                          </select>
                      </div>
                      </div>

                      <div  item className="mt-6">
                      <label htmlFor="email" className="block text-sm font-medium mt-2 leading-6 text-gray-900">
                      Enter Seller ID
              </label>
              <div item className="mt-2">
                        <input placeholder="seller ID" label="Seller ID" variant="outlined" value={formData.seller_id} onChange={handleSellerIdChange} fullWidth required  className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6" />
                      </div>
                      </div>
                      <div item className="mt-6">
                      <label htmlFor="email" className="block text-sm font-medium mt-2 leading-6 text-gray-900">
                      Enter Brand name
              </label>
              <div item className="mt-2">
                        <input placeholder="Brand name" label="Brand Name" variant="outlined" value={formData.brand_name} onChange={handleBrandNameChange} fullWidth required className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6" />
                      </div>
                      </div>

                      <div className="mt-6" item>
                      <label htmlFor="email" className="block text-sm font-medium mt-2 leading-6 text-gray-900">
                      Region
              </label>
                         
                          <div item className="mt-2">
                          <select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={formData.region}
                            onChange={handleRegionChange}
                            fullWidth
                            required
                            className="py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                          >
                              <option value="">
                                <em>None</em>
                              </option>
                              <option value="EU">Europe</option>
                              <option value="US">North America</option>
                          </select>
                          </div>
                      </div>
                   

                      <div item className="flex mt-6">
                        <button type="submit" variant="contained" color="primary" fullWidth    className="px-5 py-3 grow uppercase min-w-[120px]  bg-indigo-100 text-white  rounded-lg">Assign</button>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
        </div>
      )
    else
      return <div style={{marginTop: '20vh', marginLeft: '5vw', justifyContent: "center", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

        {
          screen === 'success' 
          ? <Typography variant="h2" color="successful">
              Assigned Successfully!
          </Typography>
          : <Typography variant="h2" color="error">
              Assign Failed
          </Typography>
        }
        <Button onClick={() => setScreen('form')} variant="contained" style={{ marginTop: '5vh'}}>OK</Button>
      </div>
}