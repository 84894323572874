import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import ListItemText from "@mui/material/ListItemText";

function SearchBar({ setQuery }) {
  return (
    <form className="flex  gap-x-4 flex-1">
      <input
        id="search-bar"
        className="block py-3 w-full border border-borderColor rounded-lg   text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        label="Enter a Brand Name"
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
      <button
        className="border hidden border-borderColor w-12 rounded-lg   text-gray-900 shadow-sm focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
        type="submit"
        aria-label="search"
      >
        <SearchIcon />
      </button>
    </form>
  );
}

export default function Search({ filters, setFilters, selectedBrand, setQuery }) {

  return (
    <div className="flex justify-between items-center gap-10">

      <SearchBar className="" setQuery={setQuery} />

      <div className=" flex-none flex  justify-between">
        <div className="flex justify-between gap-5">

          <select selected="all_platforms" className="py-3  w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6 flex-50"
            onChange={(e) => {
              setFilters({...filters, 'platform': e.target.value})
            }}> 
              <option value='all_platforms'>All</option>
                <option value='Amazon'>Amazon</option>
                <option value='Shopify'>Shopify</option>
                <option value='Walmart'>Walmart</option>
                <option value='Amazon_Ads'>Amazon Ads</option>
          </select>

          <select selected="all_statuses" className="py-3  w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6 flex-50"
            onChange={(e) => {
              setFilters({...filters, 'status': e.target.value})
            }}> 
              <option value='all_statuses'>All</option>
              <option value='active'>Active</option>
              <option value='non_active'>Non Active</option>
          </select>

        </div>
      </div>
    </div>
  );
}
