import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CloudDoneIcon from "@mui/icons-material/CloudDone";
import axios from "axios";
import { PropagateLoader } from "react-spinners";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudOffTwoToneIcon from "@mui/icons-material/CloudOffTwoTone";

import { SessionContext } from "../../../contexts/SessionContext";
import { AppContext } from "../../../contexts/AppContext";
import { DataContext } from "../../../contexts/DataContext";

const urls = {
  Amazon: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo.png",
  Shopify:
    "https://logos-world.net/wp-content/uploads/2020/11/Shopify-Logo.png",
  Walmart:
    "https://logos-world.net/wp-content/uploads/2021/11/Walmart-Logo.png",
  Amazon_Ads:
    "https://logowik.com/content/uploads/images/amazon-ads7375.jpg",
};

function AssignDialog({ brand, setNewAssignee }) {
  const { userSession } = React.useContext(SessionContext);
  const [input, setInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [screen, setScreen] = React.useState("start");
  const [failureMessage, setFailureMessage] = React.useState("");
  const handleClickOpen = () => {
    setScreen("start");
    setInput("");
    setOpen(true);
  };

  const handleAssign = () => {
    setScreen("loading");

    const uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/assign_user`;
    const payload = {
      assignee_email: input,
      assign_type: "re-assign",
      brand_integration_hash: `${brand["platform"].toUpperCase()}#${
        brand["shop_pk"]
      }_${brand["region"]}`,
    };
    const config = {
      headers: {
        Authorization: userSession.idToken.jwtToken,
      },
    };

    axios
      .post(uri, payload, config)
      .then((res) => {
        if (res.status == 200) {
          console.log("Successfully assigned the user.");
          setScreen("successful");
          setNewAssignee(input);
        }
      })
      .catch((err) => {
        setScreen("failed");
        setFailureMessage(err.response.data.message);
      });
  };

  const handleClose = () => {
    setInput("");
    setOpen(false);
  };

  const handleRetry = () => {
    setScreen("start");
    setInput("");
  };

  const handleTextChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="order-last">
      <Dialog open={open}>
        <DialogTitle>Re-Assign</DialogTitle>
        {(() => {
          if (screen === "start")
            return (
              <>
                <DialogContent>
                  <DialogContentText>
                    Enter the email address of the Assignee (either seller or
                    employee)
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={handleTextChange}
                    value={input}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleAssign}>Assign</Button>
                </DialogActions>
              </>
            );
          else if (screen === "loading")
            return (
              <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                <PropagateLoader />
              </DialogContent>
            );
          else if (screen === "successful")
            return (
              <>
                <DialogContent>
                  <DialogContentText>
                    {" "}
                    <CheckCircleIcon color="success" /> User is assigned
                    successfully!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </>
            );
          else if (screen === "failed")
            return (
              <>
                <DialogContent>
                  <DialogContentText>
                    {" "}
                    <CancelIcon color="error" /> {failureMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRetry}>Retry</Button>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </>
            );
        })()}
      </Dialog>
      <button
        className="px-4 order-last  uppercase l py-2 text-indigo-100 border border-indigo-100 rounded-lg"
        onClick={handleClickOpen}
        variant="outlined"
      >
        Re-assign
      </button>
    </div>
  );
}

function MediaCard({ brand }) {
  const { userSession } = React.useContext(SessionContext);
  const { setCurrentTab } = React.useContext(AppContext);
  const { setIntgFormData, setReportsData } = React.useContext(DataContext);

  const userRole = userSession.idToken.payload["cognito:groups"][0];

  const [newAssignee, setNewAssignee] = React.useState(null);

  const handleIntegrate = () => {
    setCurrentTab("Integrate");
    setIntgFormData({
      platform: brand["platform"],
      seller_id: brand["shop_pk"],
      region: brand["region"],
      brand_name: brand["brand_name"],
      submit: true,
    });
  };

  const handleSeeReports = () => {
    setCurrentTab("Reports");
    setReportsData({
      seller_key: brand["hash"].split("#")[1],
    });
  };

  return (
    <div className="bg-white border rounded-lg h-full border-borderColor px-6 py-5">
      <img
        src={urls[brand["platform"]]}
        className="block mx-auto h-auto w-6/12"
        title="green iguana"
      />

      <div>
        <div className="font-bold text-base pb-3 text-center" gutterBottom>
          {brand["shop_pk"]}
        </div>
        <div className="text-base pb-4 text-center" gutterBottom>
          {
            brand['platform']=='Amazon'?'Amazon SP-API'
            : brand['platform']=='Amazon_Ads'?'Amazon ADS-API'
            : ''
          }
        </div>
        <div variant="h6" color="text.secondary">
          <strong>Region: </strong> {brand["region"]}
        </div>
        <div variant="h6" color="text.secondary">
          <strong>Brand: </strong> {brand["brand_name"]}
        </div>
        <div variant="h6" color="text.secondary">
          <strong>
            {brand["integration_status"] != "ACTIVE"
              ? "Assigned to: "
              : "Integrated by : "}
          </strong>
          {newAssignee || brand["assignee_email"]}
        </div>
      </div>

      <div className="pb-2 pt-3">
        <div variant="h5">
          {brand["integration_status"] != "ACTIVE" ? (
            <>
              Pending <CloudOffTwoToneIcon color="error" />
            </>
          ) : (
            <>
              Active <CloudDoneIcon color="success" />
            </>
          )}
        </div>
      </div>

      <div className="mt-6 flex justify-between gap-5">
        {brand["integration_status"] === "ACTIVE" ? (
          <>
            {/* <p onClick={handleSeeReports} className="underline underline-offset-1 cursor-pointer">See Reports</p> */}
            <button
              variant="outlined"
              color="success"
              onClick={handleSeeReports}
              className="px-4 uppercase grow py-2 bg-indigo-100 text-white rounded-lg"
            >
              Configure
            </button>
          </>
        ) : (
          <>
            {userRole === "admin" && (
              <AssignDialog brand={brand} setNewAssignee={setNewAssignee} />
            )}
            <button
              variant="outlined"
              color="success"
              onClick={handleIntegrate}
              className="px-4 uppercase grow py-2 bg-indigo-100 text-white rounded-lg"
            >
              Integrate
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default function Details({
  brandDetails,
  brandName,
  filters,
  filteredBrands,
  selectedBrand,
  setSelectedBrand,
}) {
  return (
    <div className="mt-6 flex justify-between">
      <Typography
        className="hidden"
        align="center"
        variant="h4"
        padding="10px"
        color="text.secondary"
      >
        {/* {brandDetails.length?brandDetails[0]['brand_name']:""} */}
        {brandName}
      </Typography>

      <div className=" flex-30">
        <div className="sticky top-0 flex flex-col">
          {filteredBrands.map((bn, i) => (
            <>
              <div
                className={`mr-8 mt-4 cursor-pointer pb-2 ${
                  bn === selectedBrand ? "activeTab" : ""
                } ${bn === "all_brands" ? "" : "order-last"}`}
              >
                <div
                  onClick={() => {
                    setSelectedBrand(bn);
                  }}
                >
                  <p>{bn === "all_brands" ? "All Brands" : bn}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div container className="flex flex-wrap -mx-1 lg:-mx-4 flex-auto">
        {brandDetails.map((brand) => (
          <div
            className={`block  my-4 mt-6 px-1  w-1/4 md:w-1/2  lg:px-4  
              ${
                ((filters["platform"] == "all_platforms") |
                  (filters["platform"] == brand["platform"])) &
                ((filters["status"] == "all_statuses") |
                  (filters["status"] == "active"
                    ? brand["integration_status"] == "ACTIVE"
                    : brand["integration_status"] != "ACTIVE"))
                  ? ""
                  : "hidden"
              }
            `}
            item
          >
            <MediaCard brand={brand} />
          </div>
        ))}
      </div>
    </div>
  );
}
