import React from "react";
import axios from "axios";
import { Navigate, useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Button, Typography } from "@mui/material";

import { SessionContext } from "../../contexts/SessionContext";
import { AppContext } from "../../contexts/AppContext";
import { DataContext } from "../../contexts/DataContext";

const IntegrateRedirect = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { userSession } = React.useContext(SessionContext);
  const { setCurrentTab } = React.useContext(AppContext);
  const { setIntgFormData } = React.useContext(DataContext);

  const [screen, setScreen] = React.useState("loading");
  const [msg, setMsg] =  React.useState("");

  const spapi_oauth_code = searchParams.get("spapi_oauth_code");
  const selling_partner_id = searchParams.get("selling_partner_id");
  const state = searchParams.get("state");
  const [platform, seller_id, region, brand_name] = state.split(",");

  React.useEffect(() => {
    (async () => {
      if (userSession === null) return;

      let uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/oauth2refresh`;
      let config = {
        headers: {
          Authorization: userSession.idToken.jwtToken,
        },
      };
      let payload = {
        spapi_oauth_code: spapi_oauth_code,
        selling_partner_id: selling_partner_id,
        region: region,
        brand_name: brand_name
      }
      
      try{
        if(seller_id != selling_partner_id) throw "Seller IDs didn't match."
        let res = await axios.post(uri, payload, config);
        console.log("OAuth ref successful.");
        // console.log("RES1 ", res);
      } catch (err) {
        console.log("OAuth refresh failed: ", err);
        setScreen("failed");
        setMsg(err)
        return
      }
      
      payload = {
        hash: `${platform.toUpperCase()}#${seller_id}_${region}`,
        attr: "integration_status",
        val: "ACCESS_VERIFIED",
      };
      uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/update_data`;
      
      try {
        let res = await axios.post(uri, payload, config);
        // console.log("RES2 ", res);
        console.log("OAuth refresh success");
        setScreen("success");
      } catch (err) {
        console.log("OAuth refresh failed: ", err);
        setScreen("failed");
        setMsg(err)
      }

    })();
  }, []);

  if (screen === "loading") {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Processing the Request</Typography>
        <BeatLoader />
      </div>
    );
  } else if (screen === "success") {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="darkgreen">
          Successful
          {msg!=""? `: ${msg}`: ""}
        </Typography>
        <Button
          onClick={() => setScreen("return")}
          variant="contained"
          style={{ marginTop: "5vh" }}
        >
          Return
        </Button>
      </div>
    );
  } else if (screen === "failed") {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="error">
          Failed
          {msg!=""? `: ${msg}`: ""}
        </Typography>
        <Button
          onClick={() => {
            setScreen("return");
          }}
          variant="contained"
          style={{ marginTop: "5vh" }}
        >
          Return
        </Button>
      </div>
    );
  } else if (screen === "return") {
    setCurrentTab("Integrate");
    setIntgFormData({
      platform: platform,
      seller_id: seller_id,
      region: region,
      brand_name: brand_name,
      submit: true,
    });

    return <Navigate to="/" />;
  }
};

export default IntegrateRedirect;
