import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { StepConnector } from "@mui/material";

import { SessionContext } from "../../../contexts/SessionContext";
import { DataContext } from "../../../contexts/DataContext";

const steps = [
  {
    label: "Seller Central Access",
    description: "Please visit seller central platform and authorize.",
  },
  {
    label: "S3 Destination Access",
    description: "",
  },
  {
    label: "Integration Active",
    description: "",
  },
];

const ext = {
  EU: "de",
  US: "com",
};

const auth_url_prefix = {
  US: "https://www.amazon.com/ap/oa",
  NA: "https://www.amazon.com/ap/oa",
  EU: "https://eu.account.amazon.com/ap/oa",
  FE: "https://apac.account.amazon.com/ap/oa",
};

export default function IntegrationSteps() {
  const { userSession } = React.useContext(SessionContext);
  const { intgData, setIntgData } = React.useContext(DataContext);
  const data = intgData["data"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [apiStatus, setApiStatus] = React.useState("none");

  React.useEffect(() => {
    if (data) {
      const steps_map = {
        ASSIGNED: 0,
        ACCESS_VERIFIED: 1,
        DESTINATION_VERIFIED: 2,
        ACTIVE: 3,
      };

      setActiveStep(steps_map[data["integration_status"]]);
    }
  }, [data]);

  const state = `${data["platform"]},${data["shop_pk"]},${data["region"]},${data["brand_name"]}`;
  let auth_url = null;
  
  if (data["platform"] == "Amazon_Ads") {
    const ads_redirect_uri = 'https://app.powrxanalytics.com/integrate-redirect/ads'
    auth_url = `${auth_url_prefix[data["region"]]}?client_id=${
      process.env.REACT_APP_AMZ_ADS_APP
    }&scope=advertising::campaign_management&response_type=code&redirect_uri=${ads_redirect_uri}&state=${state}`;
  } else {
    auth_url = `https://sellercentral.amazon.${
      ext[data["region"]]
    }/apps/authorize/consent?version=beta&application_id=${
        process.env.REACT_APP_AMZ_SC_APP
      }&state=${state}`;
  }

  const handleS3Verify = (e) => {
    e.preventDefault();
    setApiStatus("loading");

    const form = document.getElementById("s3_bucket_form");

    const uri = `${process.env.REACT_APP_PROD_BACKEND}/brand_integrations/verify_destination`;
    const config = {
      headers: {
        Authorization: userSession.idToken.jwtToken,
      },
    };
    const payload = {
      key: data["hash"],
      s3_bucket: form.s3_bucket.value,
    };

    axios
      .post(uri, payload, config)
      .then((res) => {
        if (res.status == 200) {
          const new_data = res.data.data;
          setIntgData({ api_status: "success", data: new_data });
          // setApiStatus('failed')
        } else {
          setApiStatus("failed");
        }
      })
      .catch((err) => {
        console.log("failed");
      });
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Typography variant="subtitle1">Progress of {data["hash"]}</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index < activeStep ? (
                  <Typography variant="caption">Completed</Typography>
                ) : index === activeStep ? (
                  <Typography variant="caption">
                    {activeStep === 2 ? "In progress" : "Pending"}
                  </Typography>
                ) : null
              }
              error={(index === activeStep) & (index != 2)}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index === 0 && (
                    <>
                      <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        href={auth_url}
                        // target="_blank"
                      >
                        Go to Seller Central
                      </Button>
                    </>
                  )}

                  {index === 1 && (
                    <>
                      {apiStatus === "loading" ? (
                        <BeatLoader />
                      ) : (
                        <form
                          onSubmit={handleS3Verify}
                          id="s3_bucket_form"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {apiStatus === "failed" && (
                            <p style={{ color: "red" }}>
                              * Destination verification failed
                            </p>
                          )}
                          <input
                            type="text"
                            name="s3_bucket"
                            variant="outlined"
                            fullWidth
                            className="block py-3 w-full border border-borderColor rounded-md  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1  focus:ring-indigo-600  sm:text-sm sm:leading-6"
                            required
                          />
                          <Button
                            variant="contained"
                            sx={{ mt: 1, mr: 1 }}
                            type="submit"
                          >
                            Verify
                          </Button>
                        </form>
                      )}
                    </>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
